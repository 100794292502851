<template>
  <div class="container oci-check-in-out-body">
    <b-card class="oci-check-in-out-card">
      <b-row class="p-0 m-0">
        <b-col class="text-center h4">
          Job {{ jobData.job }}
        </b-col>
      </b-row>
      <b-row class="m-0">
        <b-col class="text-center">
          {{ jobData.date }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <JobDetailsStatusIcon :status="jobData.status" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          Hours:
        </b-col>
        <b-col>
          {{ jobData.hours }}
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          Client:
        </b-col>
        <b-col>
          {{ jobData.client }}
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          Address:
        </b-col>
        <b-col>
          {{ jobData.address }}&nbsp;
          <b-link>
            {{ jobData.postcode }}
          </b-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          Contact:
        </b-col>
        <b-col>
          {{ jobData.contact }}<br>
          <b-link>
            {{ jobData.contact_phone }}
          </b-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          Task:
        </b-col>
        <b-col>
          {{ jobData.task }}
        </b-col>
      </b-row>
      <div class="mt-4">
        <div class="float-left">
          <b-button class="oci-reject-button">
            <i><feather-icon
              icon="ThumbsDownIcon"
              size="21"
              class="mr-1"
              variant="danger"
            /></i>
            Reject
          </b-button>
        </div>
        <div class="float-right">
          <b-button class="oci-accept-button">
            <i><feather-icon
              icon="ThumbsUpIcon"
              size="21"
              class="mr-1"
            /></i>
            Accept
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BLink,
} from 'bootstrap-vue'
import alert from '@/components/alert.vue'
import JobDetailsStatusIcon from '@/components/ui/JobDetailsStatusIcon.vue'

export default {
  components: {
    JobDetailsStatusIcon,
    alert,
    BRow,
    BCol,
    BCard,
    BButton,
    BLink,
  },
  props: {
    // jobData: {
    //   type: Object,
    //   required: true,
    // }
  },
  data() {
    return {
      jobData: {
        status: 'rejected',
        date: 'Friday 19 November',
        job: '24710',
        hours: '8:00am - 2:00pm',
        client: 'Sandhurst Interiors',
        address: 'Ash Manor School, Manor Road, Aldershot, Surrey.',
        postcode: 'GU12 6QH',
        contact: 'John Doe',
        contact_phone: '07123456789',
        task: 'Specialist demolition works',
      },
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>
