<template>
  <div class="icon-display">
    <div
      v-if="status=='accepted'"
      class="icon-margin"
    >
      <div class="text-center">
        Accepted
      </div>
      <feather-icon
        icon="ThumbsUpIcon"
        size="40"
        class="oci-color-green"
      />
    </div>
    <div
      v-else-if="status=='rejected'"
      class="icon-margin"
    >
      <div class="text-center">
        Rejected
      </div>
      <feather-icon
        icon="ThumbsDownIcon"
        size="40"
        class="oci-color-red"
      />
    </div>
    <div
      v-else
      class="icon-margin"
    >
      <div class="text-center">
        Pending confirmation
      </div>
      <feather-icon
        icon="AlertCircleIcon"
        size="40"
        class="oci-color-orange"
      />
    </div>
  </div>
</template>

<script>
import {
  BPopover,
} from 'bootstrap-vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

export default {
  name: 'JobDetailsStatusIcon',
  components: {
    FeatherIcon,
    BPopover,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    popoverVariant() {
      if (this.data.item.operative[0].operativeproblems.errorcount > 0) return 'danger'

      return 'scl-issue-color'
    },
    problemCount() {
      return this.data.item.operative[0].operativeproblems.errorcount + this.data.item.operative[0].operativeproblems.warningcount
    },
    problemClass() {
      if (this.data.item.operative[0].operativeproblems.errorcount > 0) return 'text-danger text-left'

      return 'text-warning text-left'
    },
    badgeClass() {
      if (this.data.item.operative[0].operativeproblems.errorcount > 0) return 'badge-danger badge-glow'

      return 'badge-warning badge-glow'
    },
  },
}
</script>

<style scoped>
.popover {
 box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.error-heading {
  color: #FF5358;
}
.warning-heading {
  color: #ff9f43;
}
.icon-display {
  display:flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.icon-margin {
  margin-right: auto;
  margin-left: auto;
}

</style>
